<div class="field col-12">
  <label for="provider">Marca</label>
  <p-dropdown
    id="provider"
    [options]="this.providers"
    [formGroup]=""
    [showClear]="true"
    class="block"
    [required]="true"
    styleClass="w-12"
    optionLabel="name"
    (onChange)="this.onSelection($event)"
  ></p-dropdown>
</div>
