import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ContainerService {
  services = new Map<string, Map<string, any>>();

  constructor() {}

  register(sdk: string, instance: any) {
    if (!this.services.has(sdk)) {
      this.services.set(sdk, new Map<string, any>());
    }

    const fromsdk = this.services.get(sdk);
    if (fromsdk.has(instance.constructor.name)) {
      fromsdk.delete(instance.constructor.name);
    }

    fromsdk.set(instance.constructor.name, instance);
  }

  get<T>(sdk: string, name: string): T {
    if (!this.services.has(sdk)) {
      console.error('SDK ' + sdk + ' not found, check other: ', this.services);
    }

    const fromsdk = this.services.get(sdk);

    if (!fromsdk.has(name)) {
      console.error('Service not found, check other: ', fromsdk);
    }
    //const typename = typeof T;
    return fromsdk.get(name) as T;
  }
}
