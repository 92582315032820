/**
 * gpas-report-services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface IGames { 
    id?: number;
    provider_id?: number;
    provider_play_code?: string | null;
    provider_demo_code?: string | null;
    code?: string | null;
    name?: string | null;
    allow_bonus?: number;
    status?: string | null;
}

