import { Injectable } from '@angular/core';
import { OBillingResponseDto } from 'apis/gpas';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HolderService {
  hold = new BehaviorSubject<OBillingResponseDto | any[]>(null);
  constructor() {}
}
