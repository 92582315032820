<div id="date-range" class="flex">
  <div class="field col-6 flex flex-column align-content-center">
    <label for="icon" class="block">Data Inicial</label>
    <p-calendar
      [showIcon]="true"
      inputId="icon"
      dateFormat="dd-mm-yy"
      class="align-self-center"
      (onSelect)="this.onSelection($event, 'startDate')"
    ></p-calendar>
  </div>
  <div class="field col-6 flex flex-column align-content-center">
    <label for="icon" class="">Data Final</label>
    <p-calendar
      dateFormat="dd-mm-yy"
      [showIcon]="true"
      inputId="icon"
      class="align-self-center"
      (onSelect)="this.onSelection($event, 'endDate')"
    ></p-calendar>
  </div>
</div>
