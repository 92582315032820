import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { Dto } from '../Models/dto';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DatePipe],
})
export class DateRangeComponent implements OnInit {
  startDate: Date;
  endDate: Date;
  @Output() onChange = new EventEmitter<Dto>();

  constructor(private datePipe: DatePipe) {}

  ngOnInit(): void {}

  onSelection(data: any, key: string) {
    this.onChange.emit({
      data: this.datePipe.transform(data, 'yyyy/MM/dd'),
      type: key,
    });
  }
}
