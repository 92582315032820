// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
declare const window: any;

import { Globals } from 'src/app/shared/global';

export const environment = {
  production: true,
  apis: {
    report_api: window.env.report_api
      || 'https://dev.report.gpas.live',
    identity_api: window.env.identity_api
      || 'http://dev.api.gpas.live/v1/graphql',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
Globals.tenantId = window.env.tenant_id || '37cd1b55-addb-11eb-a467-0242ac110005';
Globals.tenantName = window.env.tenant_name || 'playbonds';
