/**
 * gpas-report-services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OConsolidatedByUsernameDto } from './oConsolidatedByUsernameDto';
import { OPropertyDto } from './oPropertyDto';


export interface OConsolidatedByUsernameResponseDto { 
    propertys?: Array<OPropertyDto> | null;
    data?: Array<OConsolidatedByUsernameDto> | null;
    totals?: OConsolidatedByUsernameDto;
}

