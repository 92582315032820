/**
 * gpas-report-services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OConsolidatedBySesionDto } from './oConsolidatedBySesionDto';
import { OPropertyDto } from './oPropertyDto';


export interface OConsolidatedBySesionResponseDto { 
    propertys?: Array<OPropertyDto> | null;
    data?: Array<OConsolidatedBySesionDto> | null;
    totals?: OConsolidatedBySesionDto;
}

