import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { IProviderGameDto, ReportService } from 'apis/gpas';
import { Globals } from '../global';
import { Dto } from '../Models/dto';

@Component({
  selector: 'app-provider',
  templateUrl: './provider.component.html',
  styleUrls: ['./provider.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProviderComponent implements OnInit {
  providers: IProviderGameDto[] = [];
  provider: IProviderGameDto;

  @Output() onChange = new EventEmitter<Dto>();

  constructor(settingService: ReportService, private cd: ChangeDetectorRef) {
    settingService
      .apiV1GpasProviderListGet(Globals.tenantId)
      .subscribe((providers: IProviderGameDto[]) => {
        // arregla sobre el response providers para que el primer provider sea el Agt
        const agtProvider = providers.find(
          (provider) => provider.name === 'Agt'
        );
        if (agtProvider) {
          // remove agtProvider from providers
          providers.splice(providers.indexOf(agtProvider), 1);
          // add agtProvider to the beginning of providers
          providers.unshift(agtProvider);
        }
        this.providers = providers;
        this.onSelection({ originalEvent: null, value: this.providers[0] });
        this.cd.detectChanges();
      });
  }

  onSelection(provider: { originalEvent: any; value: IProviderGameDto }) {
    this.onChange.emit({ data: provider.value.name, type: 'provider' });
  }

  ngOnInit(): void {}
}
