import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'folder/:id',
    loadChildren: () =>
      import('./folder/folder.module').then((m) => m.FolderPageModule),
  },
  {
    path: 'testReport',
    loadChildren: () =>
      import('./test-report/test-report.module').then(
        (m) => m.TestReportPageModule
      ),
  },
  {
    path: 'faturamento',
    loadChildren: () =>
      import('./billing/billing.module').then(
        (m) => m.BillingSearchFormPageModule
      ),
  },
  {
    path: 'general',
    loadChildren: () =>
      import('./shared/shared.module').then((m) => m.GPASSharedModule),
  },
  {
    path: 'por-jogo',
    loadChildren: () =>
      import('./by-game/by-game.module').then((m) => m.ByGamePageModule),
  },
  {
    path: 'por-periodo',
    loadChildren: () =>
      import('./by-period/by-period.module').then((m) => m.ByPeriodPageModule),
  },
  {
    path: 'por-sessão',
    loadChildren: () =>
      import('./by-session/by-session.module').then(
        (m) => m.BySessionPageModule
      ),
  },
  {
    path: 'por-usuário',
    loadChildren: () =>
      import('./by-user/by-user.module').then((m) => m.ByUserPageModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginPageModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
