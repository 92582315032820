/**
 * gpas-report-services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OConsolidatedByGameDto } from './oConsolidatedByGameDto';
import { OPropertyDto } from './oPropertyDto';


export interface OConsolidatedByGameResponseDto { 
    propertys?: Array<OPropertyDto> | null;
    data?: Array<OConsolidatedByGameDto> | null;
    totals?: OConsolidatedByGameDto;
}

