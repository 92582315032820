<ion-app>
  <!-- <identity-sdk #identitySdk></identity-sdk> -->
  <ion-header>
    <ion-toolbar>
      <ion-title class="ion-text-center">GPAS</ion-title>
      <ion-item
        slot="end"
        class="flex align-items-center"
        *ngIf="this.userService.userlogged | async"
      >
        <ion-avatar slot="start" class="flex align-items-center">
          <ion-icon size="large" name="person"></ion-icon>
        </ion-avatar>
        <p-dropdown
          [options]="tenants"
          (onChange)="onTenantChange($event)"
          appendTo="body"
        ></p-dropdown>
      </ion-item>
    </ion-toolbar>
  </ion-header>
  <ng-container *ngIf="this.userService.userlogged | async; else elseTemplate">
    <ion-split-pane contentId="main-content" style="margin-top: 57px">
      <ion-menu
        contentId="main-content"
        type="overlay"
        class="menu-pane-visible"
      >
        <ion-content>
          <p-panelMenu
            [model]="items"
            [style]="{ 'box-shadow': 'none' }"
          ></p-panelMenu>
          <ion-item
            routerDirection="root"
            routerLink="/login"
            (click)="this.logout()"
          >
            <ion-icon slot="start" name="log-in-outline"></ion-icon>
            <ion-label (click)="(this.logout)">Sair</ion-label>
          </ion-item>
        </ion-content>
      </ion-menu>

      <ion-router-outlet id="main-content"></ion-router-outlet>
    </ion-split-pane>
  </ng-container>
  -->
  <ng-template #elseTemplate>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ng-template>
</ion-app>
