import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IProviderGameDto, ReportService } from 'apis/gpas';
import { Globals } from '../global';
import { Dto } from '../Models/dto';

@Component({
  selector: 'app-provider',
  templateUrl: './provider.component.html',
  styleUrls: ['./provider.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProviderComponent implements OnInit {
  providers: IProviderGameDto[] = [];
  provider: IProviderGameDto;
  form: FormGroup;
  @Output() onChange = new EventEmitter<Dto>();

  constructor(settingService: ReportService) {
    settingService
      .apiV1GpasProviderListGet(Globals.tenantId)
      .subscribe((providers: IProviderGameDto[]) => {
        this.providers = providers;
        this.onSelection(providers[0]);
      });
  }

  onSelection(provider: IProviderGameDto) {
    this.onChange.emit({ data: provider, type: 'provider' });
  }

  ngOnInit(): void {}
}
