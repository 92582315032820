<div class="field col-12">
  <label for="coin" class="">Tipo de Relatorio</label>
  <p-dropdown
    id="coin"
    [options]="reportTypes"
    [showClear]="true"
    class="block"
    [required]="true"
    styleClass="w-12"
  ></p-dropdown>
</div>
