<div class="field col-12">
  <label for="coin" class="">Tipo Moeda</label>
  <p-dropdown
    id="coin"
    [options]="currencyTypes"
    [showClear]="true"
    class="block"
    [required]="true"
    styleClass="w-12"
    (onChange)="this.onSelection($event)"
  ></p-dropdown>
</div>
