import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Apollo, gql, MutationResult } from 'apollo-angular';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

const login = gql`
  # Try out GraphQL queries here
  mutation login($username: String!, $password: String!) {
    identity {
      login(username: $username, password: $password) {
        token
        user {
          id
          username
        }
      }
    }
  }
`;
@Injectable({
  providedIn: 'root',
})
export class UserService {
  // user: IdentityInfoFromToken;
  token = '';
  userlogged = new BehaviorSubject<boolean>(undefined);

  constructor(private router: Router, private apollo: Apollo) {}

  login() {
    this.userlogged.next(true);
  }

  async logout() {
    this.userlogged.next(false);
    this.router.navigate(['/login']);
  }

  // userChange(token: string, user: IdentityInfoFromToken) {
  //   this.token = token;
  //   this.user = user;

  //   //indicate
  //   this.userlogged.next(user != undefined);

  //   if (user == undefined) {
  //     this.router.navigate(['/login']);
  //   } else {
  //     this.router.navigate(['/faturamento']);
  //   }
  // }
  async login$(user: { username: string; password: string }) {
    return this.apollo
      .mutate({
        mutation: login,
        variables: {
          username: user.username,
          password: user.password,
        },
      })
      .pipe(
        map((mutationResult: MutationResult<{ identity: LoginOutput }>) => {
          const userOutput = mutationResult.data.identity.login;
          localStorage.setItem('token', userOutput.token);
          localStorage.setItem('username', userOutput.user.username);
          localStorage.setItem('id', userOutput.user.id);
          return userOutput;
        })
      )
      .toPromise();
  }
}
class UserOutput {
  token: string;
  user: {
    username: string;
    id: string;
  };
}

class LoginOutput {
  login: UserOutput;
}
