/**
 * gpas-report-services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OConsolidatedByGameDto { 
    sessionQty?: number;
    gameName?: string | null;
    dateFrom?: string | null;
    dateTo?: string | null;
    coinIn?: number;
    coinOut?: number;
    win?: number;
    rtp?: number;
    tentant?: string | null;
    provider?: string | null;
    currency?: string | null;
}

