/**
 * gpas-report-services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OMovementDto { 
    movementDate?: string;
    movementProviderId?: string | null;
    movementWalletId?: string | null;
    gameSession?: string | null;
    walletSession?: string | null;
    typeMovment?: string | null;
    tenant?: string | null;
    provider?: string | null;
    playerId?: string | null;
    currency?: string | null;
    gameId?: string | null;
    amount?: number;
}

