import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { Dto } from '../Models/dto';

@Component({
  selector: 'app-report-type',
  templateUrl: './report-type.component.html',
  styleUrls: ['./report-type.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportTypeComponent implements OnInit {
  reportTypes: string[] = ['Analítico', ''];
  reportType: string = this.reportTypes[0];
  @Output() onChange = new EventEmitter<Dto>();

  constructor() {}

  ngOnInit(): void {}

  onSelection(data: any, key: string) {
    this.onChange.emit({ data: data, type: key });
  }
}
