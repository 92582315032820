import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IProviderGameDto } from 'apis/gpas';
import * as FileSaver from 'file-saver';
import jsPDF from 'jspdf';
import autoTable, { RowInput } from 'jspdf-autotable';
import { HolderService } from 'src/services/holder/holder.service';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss'],
})
export class SearchResult implements OnInit {
  products: any[];
  searchResult: any;
  providers: IProviderGameDto[];
  selectedState: any = null;
  cols: { field: string; header: string }[];
  totals: any;
  exportColumns: RowInput[];

  constructor(private holder: HolderService, router: Router) {
    holder.hold.subscribe((data: any) => {
      if (!!data && (data as any)) {
        this.searchResult = data;
        this.totals = this.searchResult.totals;
        this.products = data.data;
        this.cols = data.propertys;
      } else {
        router.navigate(['./']);
      }
    });
  }

  ngOnInit() {}

  exportExcel() {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(this.products);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      this.saveAsExcelFile(excelBuffer, 'products');
    });
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  exportPdf() {
    this.exportColumns = [
      this.cols.map((col) => {
        return col.header;
      }),
    ];
    const doc = new jsPDF({ orientation: 'landscape' });
    /*
    coinIn: 166
    coinOut: 183.9
    currency: "BRL"
    dateFrom: "01/10/2021"
    dateTo: "31/10/2021"
    provider: "Agt"
    rtp: 110.78
    sessionQty: 9
    shortDate: "Oct/2021"
    tentant: "Bingolar"
    win: -17.9
*/
    autoTable(doc, {
      head: this.exportColumns,
      body: this.products.map((product) => {
        const result: string[] = [];
        this.cols.forEach((col) => {
          result.push(product[col.field]);
        });
        return result;
      }),
      headStyles: { cellWidth: 'auto' },
    });
    doc.setLanguage('pt-BR');
    doc.save('tabla resultado.pdf');

    // import('jspdf').then((jsPDF) => {
    //   import('jspdf-autotable').then((jspdfAutotable) => {
    //     const doc = new jspdfAutotable.default(this.products, {
    //       bodyStyles: { fontSize: 16 },
    //     });
    //     doc.setLanguage('pt-BR');
    //     const z = [];
    //     this.cols.forEach((col) => {
    //       z.push(col.header);
    //     });
    //     doc.save('products.pdf');
    //   });
    // });
  }
}
