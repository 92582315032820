import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ReportService } from 'apis/report_gpas';
import { MenuItem, PrimeNGConfig } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { UserService } from 'src/services/userService/user-service.service';
import { Globals } from './shared/global';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  // @ViewChild('identitySdk', { static: false })
  // identitySdk: ElementRef<HTMLIdentitySdkElement>;
  items: MenuItem[];
  isUserLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject(false);
  title: string = Globals.tenantName;
  tenants: string[];
  selectedTenant: { value: string; originalEvent: any } = {
    value: 'NGX',
    originalEvent: null,
  };

  public appPages = [
    { title: 'Inbox', url: '/folder/Inbox', icon: 'mail' },
    { title: 'Outbox', url: '/folder/Outbox', icon: 'paper-plane' },
    { title: 'Favorites', url: '/folder/Favorites', icon: 'heart' },
    { title: 'Archived', url: '/folder/Archived', icon: 'archive' },
    { title: 'Trash', url: '/folder/Trash', icon: 'trash' },
    { title: 'Spam', url: '/folder/Spam', icon: 'warning' },
  ];

  // private settingService: SettingService,
  // private reportService: ReportService
  constructor(
    private primengConfig: PrimeNGConfig,
    public userService: UserService,
    private reportService: ReportService,
    private router: Router
  ) {
    this.primengConfig.ripple = true;
    this.userService.userlogged.subscribe((user) => {
      if (user) {
        this.reportService.configuration.basePath =
          'https://report-api.gpas.live';
        this.reportService
          .apiV1GpasTenantListGet()
          .subscribe((tenants: string[]) => {
            this.tenants = tenants;
            // select NGX tenant
            // Move to the first tenant the NGX
            this.tenants.splice(this.tenants.indexOf('NGX'), 1);
            this.tenants.unshift('NGX');
            Globals.tenantName = 'NGX';
          });
      } else {
        this.router.navigate(['/login']);
      }
    });
  }
  ngOnInit() {
    this.items = [
      {
        label: 'Relatórios',
        icon: 'pi pi-pw pi-chart-bar',
        items: [
          {
            label: 'Consolidado por Jogo',
            routerLink: 'por-jogo',
          },
          {
            label: 'Consolidado por Usuario',
            routerLink: '/por-usuário',
          },
          {
            label: 'Consolidado por Session',
            routerLink: '/por-sessão',
          },
          {
            label: 'Por Periodo',
            routerLink: 'por-periodo',
          },
          {
            label: 'Faturamento',
            routerLink: '/faturamento',
          },
        ],
      },
    ];
  }

  onTenantChange(tenant: { originalEvent: any; value: string }) {
    Globals.tenantName = tenant.value;
  }

  async logout() {
    await this.userService.logout();
    // this.identitySdk.nativeElement.logout();
  }
}
