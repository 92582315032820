import { Component } from '@angular/core';
import { MenuItem, PrimeNGConfig } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { ContainerService } from 'src/services/containerService/container.service';
import { UserService } from 'src/services/userService/user-service.service';
import { Globals } from './shared/global';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  // @ViewChild('identitySdk', { static: false })
  // identitySdk: ElementRef<HTMLIdentitySdkElement>;
  items: MenuItem[];
  isUserLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject(false);
  title: string = Globals.tenantName;

  public appPages = [
    { title: 'Inbox', url: '/folder/Inbox', icon: 'mail' },
    { title: 'Outbox', url: '/folder/Outbox', icon: 'paper-plane' },
    { title: 'Favorites', url: '/folder/Favorites', icon: 'heart' },
    { title: 'Archived', url: '/folder/Archived', icon: 'archive' },
    { title: 'Trash', url: '/folder/Trash', icon: 'trash' },
    { title: 'Spam', url: '/folder/Spam', icon: 'warning' },
  ];

  // private settingService: SettingService,
  // private reportService: ReportService
  constructor(
    private primengConfig: PrimeNGConfig,
    public userService: UserService,
    private controllerService: ContainerService
  ) {
    this.primengConfig.ripple = true;
  }
  ngOnInit() {
    this.items = [
      {
        label: 'Relatórios',
        icon: 'pi pi-pw pi-chart-bar',
        items: [
          {
            label: 'Consolidado por Jogo',
            routerLink: 'por-jogo',
          },
          {
            label: 'Consolidado por Usuario',
            routerLink: '/por-usuário',
          },
          {
            label: 'Consolidado por Session',
            routerLink: '/por-sessão',
          },
          {
            label: 'Por Periodo',
            routerLink: 'por-periodo',
          },
          {
            label: 'Faturamento',
            routerLink: '/faturamento',
          },
        ],
      },
    ];
  }

  // async ngAfterViewInit() {
  //   await this.sdkInit();
  // }

  // async sdkInit() {
  //   // login Component Event - cambio de token

  //   this.identitySdk.nativeElement.addEventListener('userChange', async (e) => {
  //     console.log('tokenChange event - ' + e['detail']);
  //     this.isUserLoggedIn.next(true);
  //     this.userService.userChange(e['detail'].token, e['detail'].user);

  //     const identityServices =
  //       await this.identitySdk.nativeElement.getPublicServices();
  //     Object.entries(identityServices).forEach((t) => {
  //       this.controllerService.register('identity', t[1]);
  //     });
  //   });

  //   await this.identitySdk.nativeElement.configure({
  //     basePath: 'https://v2-dev.esportesbar.com/apis/identity',
  //   });

  //   //Register all Apis, to be used on
  //   const identityServices =
  //     await this.identitySdk.nativeElement.getPublicServices();

  //   Object.entries(identityServices).forEach((t) => {
  //     this.controllerService.register('identity', t[1]);
  //   });
  // }

  async logout() {
    await this.userService.logout();
    // this.identitySdk.nativeElement.logout();
  }
}
