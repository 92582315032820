import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { CurrencyComponent } from './currency/currency.component';
import { DateRangeComponent } from './date-range/date-range.component';
import { OrderByComponent } from './order-by/order-by.component';
import { ProviderComponent } from './provider/provider.component';
import { ReportTypeComponent } from './report-type/report-type.component';
import { SearchResult } from './search-result/search-result.component';
import { SharedRoutingModule } from './shared.routing-module';

@NgModule({
  declarations: [
    SearchResult,
    CurrencyComponent,
    DateRangeComponent,
    ReportTypeComponent,
    ProviderComponent,
    OrderByComponent,
  ],
  imports: [
    CommonModule,
    TableModule,
    ReactiveFormsModule,
    IonicModule,
    SharedRoutingModule,
    DropdownModule,
    CalendarModule,
  ],
  exports: [
    DateRangeComponent,
    CurrencyComponent,
    ReportTypeComponent,
    ProviderComponent,
    OrderByComponent,
  ],
})
export class GPASSharedModule {}
