import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Dto } from '../Models/dto';

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss'],
})
export class CurrencyComponent implements OnInit {
  currencyTypes: string[] = ['BRL', 'USD', 'EUR'];
  currency = { value: 'BRL' };
  @Output() onChange = new EventEmitter<Dto>();

  constructor() {}

  onSelection(currency) {
    const result: Dto = { data: currency.value, type: 'currency' };
    this.onChange.emit(result);
  }

  ngOnInit() {
    this.onSelection(this.currency);
  }
}
