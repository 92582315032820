import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AvatarModule } from 'primeng/avatar';
import { DropdownModule } from 'primeng/dropdown';
import { PanelMenuModule } from 'primeng/panelmenu';
import { environment } from 'src/environments/environment';
import { BASE_PATH, ApiModule as GPASApi } from '../../apis/gpas'; //la ruta a la carpeta generada
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GraphQLModule } from './graphql.module';
import { GPASSharedModule } from './shared/shared.module';
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    GPASApi,
    HttpClientModule,
    BrowserAnimationsModule,
    PanelMenuModule,
    AvatarModule,
    GPASSharedModule,
    GraphQLModule,
    DropdownModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: BASE_PATH, useValue: environment.apis.report_api },
    // { provide: BASE_PATH_GPAS, useValue: 'https://dev.gpas.playbonds.com' },
    // { provide: BASE_PATH_REPORTGPAS, useValue: 'https://dev.report.gpas.cc' },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
