<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>Resltado de busqueda</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <p-table
    #dt
    *ngIf="!!this.products"
    [value]="products"
    responsiveLayout="scroll"
    [columns]="this.cols"
  >
    <ng-template pTemplate="caption">
      <div class="flex justify-content-between">
        <div class="flex align-items-center">
          Viendo en total {{ dt.totalRecords }} registros.
        </div>
        <div>
          <button
            type="button"
            pButton
            pRipple
            icon="pi pi-file-o"
            (click)="dt.exportCSV()"
            class="mr-2"
            pTooltip="CSV"
            tooltipPosition="bottom"
          ></button>
          <button
            type="button"
            pButton
            pRipple
            icon="pi pi-file-excel"
            (click)="exportExcel()"
            class="p-button-success mr-2"
            pTooltip="XLS"
            tooltipPosition="bottom"
          ></button>
          <button
            type="button"
            pButton
            pRipple
            icon="pi pi-file-pdf"
            (click)="exportPdf()"
            class="p-button-warning p-mr-2"
            pTooltip="PDF"
            tooltipPosition="bottom"
          ></button>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="summary">
      <div class="flex align-items-center justify-content-between">
        Viendo en total {{ dt.totalRecords }} registros.
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <ng-container *ngFor="let propiedad of this.searchResult.propertys">
          <ng-container
            *ngIf="propiedad.header != ''; then thenTemplate; else elseTemplate"
          ></ng-container>
          <ng-template #thenTemplate>
            <th [pSortableColumn]="propiedad.field">
              {{ propiedad.header }}
              <p-sortIcon [field]="propiedad.field"></p-sortIcon>
            </th>
          </ng-template>
          <ng-template #elseTemplate>
            <th>
              {{ propiedad.header }}
            </th>
          </ng-template>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product>
      <tr>
        <td *ngFor="let propiedad of this.searchResult.propertys">
          {{ product[propiedad.field] }}
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-table
    #dt
    *ngIf="!!this.totals"
    [value]="[totals]"
    responsiveLayout="scroll"
    [columns]="this.cols"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex">
        <button
          type="button"
          pButton
          pRipple
          icon="pi pi-file-o"
          (click)="dt.exportCSV()"
          class="p-mr-2"
          pTooltip="CSV"
          tooltipPosition="bottom"
        ></button>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <ng-container *ngFor="let propiedad of this.searchResult.propertys">
          <th *ngIf="!!totals[propiedad.field]">
            {{ propiedad.header }}
          </th>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product>
      <tr>
        <ng-container *ngFor="let propiedad of this.searchResult.propertys">
          <td *ngIf="!!totals[propiedad.field]">
            {{ totals[propiedad.field] }}
          </td>
        </ng-container>
      </tr>
    </ng-template>
  </p-table>
</ion-content>
